<template>
  <div class="member-refer-index d-flex justify-center">
    <div class="card-wrap d-flex flex-column align-center w-full">
      <pageTitle icon="icon-member-red" text="memberRefer"></pageTitle>

      <div class="rem-md-13 rem-8">{{ $t('myReferCode') }}</div>
      <div class="red--text mb-5 rem-13">{{ `0${$store.state.userInfo.phone_number}` }}</div>

      <div id="qrcode"></div>

      <div class="rem-md-13 rem-8">{{ $t('referLink') }}</div>
      <div class="red--text text-center rem-13 d-flex align-center">
        <div>{{ `${$store.state.webUrl}/${$store.state.locale}/register?referer=0${$store.state.userInfo.phone_number}` }}</div>
        <v-icon color="primary" class="can-click" @click="copy()">mdi-clipboard-multiple-outline</v-icon>
      </div>

      <h2 class="text-center text-decoration-underline my-10 red--text can-click rem-13" @click="$router.push({name: 'Member-Refer-List', params: {lang: $store.state.locale}})">{{ $t('myRefers') }}</h2>
    </div>
  </div>
</template>
<script>
import base from '@/mixin/base'
import pageTitle from '@/components/pageTitle.vue'
const qrcode = require('qrcode-generator')
export default {
  name: "Member-Refer",
  mixins: [base],
  components:{
    pageTitle,
  },
  methods:{
    copy(){
      try{
        this.$copyText(`${this.$store.state.webUrl}/${this.$store.state.locale}/register?referer=0${this.$store.state.userInfo.phone_number}`)
        this.$toasted.show(this.$t('copySuccess'))
      }catch(error){
        console.log('error', error)
        this.$toasted.error(this.$t('copyFail'))
      }
    }
  },
  mounted(){
    var qr = qrcode(8, 'L');
    qr.addData(`${this.$store.state.webUrl}/${this.$store.state.locale}/register?referer=0${this.$store.state.userInfo.phone_number}`);
    qr.make({width: 200});
    document.getElementById('qrcode').innerHTML = qr.createImgTag();
  }
}
</script>
<style lang="scss">
.member-refer-index{
  #qrcode img{
    width: 100px;
    height: 100px;
    @media(max-width: 960px){
      width: 200px;
      height: 200px;
    }
  }
}
</style>